<template>
  <v-app>
    <app-header
      :headerOption="headerOption"
      ref="appheader"
      style="height : 0px"
      hidden
    ></app-header>
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="6" style="text-align : center;"
              ><img
                class="header_logo"
                src="@/assets/images/logo_default.svg"
                @click="goHome()"
                style="cursor : pointer"
            /></v-col>
            <v-col cols="12" md="6"><h1 style="font-size : 32px"></h1></v-col>
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div class="context flex withSide" style="margin-top : 130px">
        <side-menu
          :sideSet="sideSet"
          @call-pop-in-side-password="callPopWithSidePassword"
          @call-pop-in-side-issue="callPopWithSideIssue"
          @draw-done="initAuthInfo"
          @click-list="listReaded"
          style="margin-top : 55px"
        ></side-menu>
        <div style="width: 80%; margin-top: -50px">
          <div style="height: 50px" />
          <div
            style="width: 100%; text-align: center; font-size: 32px; margin: 10px;"
          >
            <span>쿠폰 등록</span>
          </div>
          <div class="row" style="position: relative; left: 10%; width: 1300px">
            <div class="col-md-9 col-sm-9 col-xs-12">
              <v-divider></v-divider>
              <div style="height : 20px"></div>
              <div class="row text-center">
                <div class="search">
                  <div class="item">
                    <div class="s-title">
                      <div class="s-img-wrap">
                        <v-icon
                          x-large
                          dark
                          style="position : relative; top : -3px;"
                        >
                          mdi-ticket
                        </v-icon>
                      </div>
                      <h3>
                        쿠폰번호를 입력해 주세요
                      </h3>
                    </div>
                    <div class="s-action">
                      <div class="s-input-wrap">
                        <input
                          id="input"
                          type="text"
                          v-model="couponStr"
                          @keyup.enter="registCoupon()"
                        />
                      </div>
                      <div class="s-btn-wrap">
                        <button type="button" @click="registCoupon()">
                          쿠폰 등록
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height : 50px"></div>
              <div style="font-weight : bold; font-size : 23px;">
                사용 가능한 쿠폰
              </div>
              <v-divider></v-divider>
              <div>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center; font-weight : bold;"
                  >
                    <span>쿠폰명</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center; font-weight : bold;"
                  >
                    <span>쿠폰번호</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="min-width : 250px; text-align : center; font-weight : bold;"
                  >
                    <span>쿠폰기간</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center; font-weight : bold;"
                  >
                    <span>쿠폰등록일자</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center; font-weight : bold;"
                  >
                    <span>쿠폰금액</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-for="(listItem, index) in list" :key="index + 'A'">
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center"
                  >
                    <span>{{ listItem.cponNm }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center"
                  >
                    <span>{{ listItem.cponId }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="min-width : 250px; text-align : center;"
                  >
                    <span
                      >{{ $moment(listItem.validStrDt).format("YYYY-MM-DD") }} ~
                      {{
                        $moment(listItem.validEndDt).format("YYYY-MM-DD")
                      }}</span
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center"
                  >
                    <span>{{
                      $moment(listItem.regDt).format("YYYY-MM-DD")
                    }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    style="width : 150px; text-align : center"
                  >
                    <span>{{
                      listItem.cponAmt.toString().replaceAll("-", "")
                    }}</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
              <div style="height : 50px" />
              <div class="pagination-wrap" style="left : 0px;">
                <v-row>
                  <v-col cols="12" md="2" style="width : 10px"></v-col>
                  <v-col
                    cols="12"
                    md="1"
                    style="width : 10px; cursor : pointer"
                  >
                    <p
                      class="navigation-act prev"
                      v-if="listStatus.first > 1"
                      @click="navigatorAct('prev')"
                    >
                      <v-icon
                        large
                        color="teal darken-2"
                        style="left : 50px; top : 0px; z-index : 1"
                      >
                        mdi-chevron-left
                      </v-icon>
                    </p>
                  </v-col>
                  <v-col cols="12" md="4" style="cursor : pointer">
                    <ul
                      id="pagination"
                      style="display : flex; text-align : center"
                    >
                      <li
                        class="btnPagination"
                        v-for="(pagination,
                        index) in listStatus.currentPaginationList"
                        :key="index + 'j'"
                        @click="loadList(pagination, index)"
                        style="width : 40px"
                      >
                        <p style="top: 5px; position : relative">
                          {{ pagination }}
                        </p>
                      </li>
                    </ul>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    style="width : 10px; cursor : pointer"
                  >
                    <p
                      class="navigation-act next"
                      v-if="
                        listStatus.next > 5 &&
                          listStatus.last < listStatus.totalPage
                      "
                      @click="navigatorAct('next')"
                    >
                      <v-icon
                        large
                        color="teal darken-2"
                        style="left : -50px; top : 0px"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </p>
                  </v-col>
                  <v-col cols="12" md="2" style="width : 10px"></v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-footer></app-footer> -->
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import SideMenu from "@/components/sidemenu/SideMenu";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { SideMenu, DefaultPopup, AppHeader },
  data() {
    return {
      popupSet: {},
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      couponStr: "",
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
    };
  },
  created() {
    this.config();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    document.querySelector(".registCoupon").classList.add("active"); //사이드메뉴 불키기
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkSearchPopup"]),
    ...mapGetters("prod", ["GET_PROD_LIST", "GET_COUPON_LIST", "GET_COUNT"]),
    ...mapGetters("admin", ["GET_PRGS_LIST", "GET_COUPON_DATA"]),
  },
  methods: {
    ...mapActions("admin", ["BRING_COUPON_DATA"]),
    ...mapActions("prod", [
      "BRING_PROD_LIST",
      "BRING_USER_COUPON_USABLE_LIST",
      "BRING_USER_COUPON_COUNT",
      "ADD_USER_COUPON",
    ]),
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_CHILD_LOADER",
      "SET_SEARCH_POPUP",
    ]),
    ...mapActions("admin", ["BRING_PRGS_LIST"]),
    initListStatus() {
      this.listStatus.totalCount = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    async config() {
      this.currentPage = 0; // 현재 페이지
      this.initListStatus();
      this.list = [];
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.BRING_USER_COUPON_USABLE_LIST(searchOpt).then(() => {
          // console.log(this.GET_COUPON_LIST);
          this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
          this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
          let tempList = JSON.parse(JSON.stringify(this.GET_COUPON_LIST.list));
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = tempList;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async registCoupon() {
      // console.log("coupon");
      //console.log(this.couponStr);
      if (this.couponStr !== "") {
        await this.BRING_USER_COUPON_COUNT(this.couponStr).then(() => {
          // console.log("기등록 쿠폰 갯수 : ", this.GET_COUNT);
        });

        if (this.GET_COUNT > 0) {
          this.popupSet.title = "등록된 쿠폰 존재";
          this.popupSet.content =
            "이미 등록하신 쿠폰번호입니다. 쿠폰번호 및 아래 리스트를 확인하세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.SET_POPUP(true);
        } else {
          await this.BRING_COUPON_DATA(this.couponStr).then(() => {
            //  console.log("데이터 값1", this.GET_COUPON_DATA);
            //  console.log("데이터 값", this.GET_COUPON_DATA.cponNm === null);
          });
          if (
            this.GET_COUPON_DATA.cponNm === null ||
            this.GET_COUPON_DATA.validEndDt <
              this.$moment().format("YYYYMMDD") ||
            this.GET_COUPON_DATA.useYn === "N"
          ) {
            this.popupSet.title = "쿠폰 등록 실패";
            this.popupSet.content =
              "유효하지 않은 쿠폰번호입니다. 대소문자 구분 및 숫자를 확인하세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.SET_POPUP(true);
          } else {
            try {
              await this.ADD_USER_COUPON(this.couponStr).then(() => {
                this.SET_POPUP(true);
                this.popupSet.title = "쿠폰 등록 완료";
                this.popupSet.content = "쿠폰 등록이 완료되었습니다.";
                this.popupSet.popType = "refund";
                this.popupSet.confirmBtnText = undefined;
                this.popupSet.cancelBtnText = "확인";
              });
            } catch (error) {
              console.log(error);
              this.SET_POPUP(true);
              this.popupSet.title = "쿠폰 등록 실패";
              this.popupSet.content =
                "오류가 발생하였습니다. 쿠폰번호를 확인하세요";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            }
          }
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 등록 실패";
        this.popupSet.content =
          "쿠폰번호는 공백이 될 수 없습니다. 쿠폰번호를 확인하세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    goHome() {
      this.$router.push("/").catch(()=>{});
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      /*document
        .querySelector("#pagination")
        .childNodes[0].classList.add("active");*/
      /*if (this.listStatus.totalCount >= 1) {
        if (order > this.listStatus.last) {
          order = this.listStatus.last - 1;
        }
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }*/
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.BRING_USER_COUPON_USABLE_LIST(searchOpt).then(() => {
          // console.log(this.GET_COUPON_LIST);
          this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
          this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
          let tempList = JSON.parse(JSON.stringify(this.GET_COUPON_LIST.list));
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = tempList;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalCount = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      // console.log("==========================================================");
      // console.log(this.listStatus);
      const totalCount = this.listStatus.totalCount;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalCount", totalCount);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalCount / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalCount: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      // console.log(first);
      // console.log(last);
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatus.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            // console.log(
            //   "재료",
            //   document.querySelector("#pagination").childNodes[k].childNodes[0]
            //     .textContent
            // );
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target = document.querySelectorAll(".tab-wrapper")[
              this.currentTab
            ];
            if (target !== undefined) {
              target
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            // console.log(document.querySelector("#pagination"));
            // console.log((this.listStatus.currentPage % 5) - 1);
            if (this.listStatus.currentPage > 5) {
              if (this.listStatus.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination")
                  .childNodes[
                    (this.listStatus.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      // console.log(type);
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100% !important;
  margin: 0;
  padding: 68px 0;
  background-image: linear-gradient(to right, #313c49 0%, #146f83 165%);
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  .item {
    max-width: 830px;
    margin: 0 auto;
  }
  .s-title {
    display: flex;
    align-items: center;
    margin: 0 0 30px 0;
    .s-img-wrap {
      margin: 0 15px 0 0;
      img {
      }
    }
    h3 {
      font-family: SUIT;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  .s-action {
    display: flex;
    .s-input-wrap {
      width: calc(100% - 155px);
      margin: 0 20px 0 0;
      input {
        border: 0;
        padding: 0 15px;
        line-height: 55px;
        width: 100%;
        background-color: #ffffff;
      }
    }
    .s-btn-wrap {
      width: 135px;
      height: 55px;
      button {
        font-family: SUIT;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 55px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 0;
        border: 0;
        width: inherit;
        background: #ff7334;
        cursor: pointer;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }
}
.btnPagination {
  &.active {
    border-radius: 3px;
    background-color: #146f83;
    p {
      color: #fff;
    }
  }
}

input, textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
